
















import UserSendMessageModal from '@/components/Profile/UserSendMessageModal.vue'
import { UsersService } from '@/includes/services/UsersService'
import { ShopService } from '@/includes/services/ShopService'
import { SlimBoardInfo } from '@/includes/types/Board.types'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import router from '@/router'

import BotUserProfile from 'piramis-base-components/src/components/BotUserProfile/Profile.vue'
import { UserProfileConfig } from 'piramis-base-components/src/components/BotUserProfile/types'
import { Notice } from 'piramis-base-components/src/components/Shop/Notices/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { UserSendMessageConfigType } from 'piramis-base-components/src/components/BotUsers/components/UserSendMessage/types'
import { User, UserProfileState } from 'piramis-base-components/src/components/BotUsers/components/Users/types'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    BotUserProfile,
  }
})
export default class Profile extends Vue {
  get board(): string {
    return this.$store.state.boardsState.activeBoard!.board
  }

  get config(): UserProfileConfig {
    return {
      mediaTargetId: this.board,
      user_id: Number(this.$route.params.user_id),
      getProfile: (id) => new Promise(resolve => {
        UsersService.getProfile('tg', { board_key: this.board, id })
          .then(({ data }) => {
            let profile = data.subscriptions ? data : Object.assign(data, { subscriptions: [] })
            //todo
            profile.properties.custom = profile.properties.custom.filter(p => (p.property as any).parent === null)
            profile.properties.system = profile.properties.system.filter(p => {
              return ![
                'USER_ID',
                'UTM',
                'USER_LOGIN',
                'START_TIME',
                'USER_NAME',
                'LAST_ACTION_TIME',
              ].includes(p.property.name)
            })

            resolve(profile)
          })
      }),
      cardHelper: {
        setUserAttributeInfo: {
          setUserAttribute: (user_id, attribute) => {
            return UsersService.setUserAttribute('tg', this.board, user_id, attribute)
              .then(() => {
                successNotification(this.$t('success').toString())
                return true
              })
              .catch(errorNotification)
          }
        },
        getLabels: () => this.$store.getters.labels,
        ban: (id) => UsersService.banUser('tg', { board_key: this.board, user_id: id }),
        unban: (id) => UsersService.unbanUser('tg', { board_key: this.board, user_id: id }),
        addLabel: (user_id, label_id) => UsersService.addBotUserLabel('tg', {
          board_key: this.board,
          user_id,
          label_id
        }),
        removeLabel: (user_id, label_id) => UsersService.deleteBotUserLabel('tg', {
          board_key: this.board,
          user_id,
          label_id
        }),
        createLabel: (name) => this.$store.dispatch('createLabel', name),
        userSendMessage: (id: number, login: string, state: User['state']) => {
          if (state === UserProfileState.Active) {
            return [ {
              type: UserSendMessageConfigType.Component,
              title: this.$i18n.t('user_profile_bot_send_message').toString(),
              view: UserSendMessageModal
            } ]
          }

          return []
        },
        gotoUser: id => {
          this.$router.push({
            params: {
              user_id: id.toString()
            }
          })
        }
      },
      tabsHelper: {
        properties: {
          getProperties: () => this.$store.getters.propertiesList,
          setProperty: (user_id, property_id, value) => UsersService.setBotUserProperty('tg', {
            board_key: this.board,
            user_id,
            property_id,
            value
          }),
          removeProperty: (user_id, property_id) => UsersService.deleteBotUserProperty('tg', {
            board_key: this.board,
            user_id,
            property_id
          }),
        },
        notices: {
          getNotices: (offset, limit, customer_id) => new Promise(resolve => {
            ShopService.getNotices('tg', { board_key: this.board, offset, limit, customer_id })
              .then((res) => resolve(res as { items: Array<Notice> }))
          })
        },
        subscriptions: {
          createSubscription: (user_id, channel_id, duration) => new Promise(resolve => {
            UsersService.createSubscription('tg', {
              board_key: this.board,
              user_id,
              channel_id,
              duration
            })
              .then(({ data }) => resolve(data))
              .catch(errorNotification)
          }),
          removeSubscription: (user_id, channel_id) => UsersService.deleteSubscription('tg', {
            board_key: this.board,
            user_id,
            channel_id
          }),
          getChannelTitle: (id) => {
            const board: SlimBoardInfo = this.$store.getters.getBoardById(this.$route.params[EntityTypes.BOT_ID])

            if (board) {
              const target = this.$store.getters.activeBoardChannels.find((t) => t.id === id)
              return target ? target.title : undefined
            }
            return undefined
          },
          getFreeChannelsOptions: (busy) => {
            const board = this.$store.state.boardsState.activeBoard

            if (board) {
              return this.$store.getters.activeBoardChannels.filter((t) => !busy.includes(t.id))
                .map((t) => {
                  return {
                    label: t.title,
                    value: t.id,
                  }
                })
            }

            return []
          },
          getChannelSubscriptionLink: (channelId) => router.resolve({
            name: 'Channel_subscription',
            params: {
              [ EntityTypes.BOT_ID ]: router.currentRoute.params[ EntityTypes.BOT_ID ],
              [ EntityTypes.CHANNEL_ID ]: channelId.toString()
            }
          }).href,
          getChannelSubscriptionType: channelId => {
            const board = this.$store.state.boardsState.activeBoard

            if (board) {
              const channel = board.channels.find(c => c.id === channelId)

              return channel && channel.limited_subscription_type
            }
          },
        },
      }
    }
  }
}
